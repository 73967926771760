import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";

import IconInsta from "../assets/svg/instagram.svg";
import IconLogo from "../assets/svg/logo.svg";

import { Link, animateScroll as scroll } from 'react-scroll'
class HomeIndex extends React.Component {
  render() {
    const siteTitle = "Laura Garrigan";
    const siteDescription =
      "Family • Seascapes • Sky • What catches the eye Skerries, Dublin, Ireland";

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
          <link rel="dns-prefetch preconnect" href="https://ddools.imgix.net" />
          <meta
            name="description"
            content="Family • Seascapes • Sky • What catches the eye
Skerries, Dublin, Ireland"
          />
          <meta name="image" content="" />
          <meta itemprop="name" content="Laura Garrigan" />
          <meta
            itemprop="description"
            content="Family • Seascapes • Sky • What catches the eye
Skerries, Dublin, Ireland"
          />
          <meta itemprop="image" content="?w=1200" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="Laura Garrigan" />
          <meta name="twitter:image" content="?w=1200" />
          <meta
            name="twitter:description"
            content="Family • Seascapes • Sky • What catches the eye
Skerries, Dublin, Ireland"
          />
          <meta property="og:title" content="Laura Garrigan" />
          <meta
            property="og:description"
            content="Family • Seascapes • Sky • What catches the eye
Skerries, Dublin, Ireland"
          />
          <meta property="og:image" content="?w=1200" />
          <meta property="og:url" content="https://www.lauragarrigan.com" />
          <meta property="og:site_name" content="Laura Garrigan" />
          <meta property="og:locale" content="en" />
          <meta property="og:type" content="website" />
        </Helmet>

        <article className="avenir">
          <div className="vh-100 dt w-100 tc bg-dark-gray white cover main-cover">
            <div className="dtc v-mid bg-black-40">
              <nav className="dt w-100 mw10 center absolute top-0">
                <div className="dtc w2 v-top pa3" />
                <div className="dtc  tr pa3">
                  <Link
                    to="two"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    aria-label="View Gallery"
                    role="button"
                    aria-pressed="false"
                    className="f6 fw4 hover-white no-underline white-70 dn dib-l pv2 ph3 pointer"
                  >
                    Gallery
                  </Link>
                  <Link
                    to="three"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    aria-label="Complete form to contact me"
                    role="button"
                    aria-pressed="false"
                    className="f6 fw4 pointer hover-white no-underline white-70 dib ml2 pv2 ph3 ba"
                  >
                    Contact me
                  </Link>
                </div>
              </nav>

              <div className="w-40-ns w-60-m w-100 center">
							<IconLogo />
							
              </div>
              <blockquote className="ph0 mh0 measure f4 lh-copy center">
                <p className="fw1 white-70 mt3">
                  email:
                  <a
                    href="mailto:lauragarriganphotography@gmail.com?subject=website enquiry"
                    className="white ml2 no-underline"
                    rel="noopener noreferrer"
                  >
                    lauragarriganphotography@gmail.com
                  </a>
                </p>
              </blockquote>

              <a
                className="dib"
                href="https://www.instagram.com/lauragarriganphotography/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className=" w3 pv2 ph2">
								<IconInsta />
                </div>
              </a>
            </div>
          </div>

          <div className="cf pa2">
						<h2 className="f1 lh-title tc fw2 avenir">About me</h2>
							<div className="w-100 dib">
								<div className="mw8 center">
									<div className="w-25-ns w-100 fl tc ttu tc b ph5 pv3 Athelas tracked-mega">
									SKERRIES<br/>based<br/><br/><br/> family<br/><br/><br/> photograher
									</div>
									<div className="w-50-ns w-100 fl  tc ph4 pv3 avenir nested-copy-line-height"><p className="pt0 mt0">
									Dublin-based photographer, specialising in newborn and family photography in the home. Photographing new babies and the detail of family life is a passion of mine, as a mother of 4 I know how quickly time passes and the importance of capturing those special, sometimes fleeting moments x</p>
									</div>
									<div className="w-25-ns w-100 fl  ttu tc b ph4 pv3 Athelas tracked-mega">
									Mother <br/><br/><br/>Photographer <br/><br/><br/> Teacher
									</div>
								</div>
							</div>
	
            <h2 className="f1 lh-title tc fw2 avenir" id="two">Gallery</h2>
            <div className="fl w-100 w-50-ns ph2">
						<div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img-b1.jpg?w=800&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img1.jpg?w=800&auto=format&auto=compress"
                />
              </div>
              <div className="no-underline pv2 db">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img2.jpg?w=800&auto=format&auto=compress"
                />
              </div>
              <div className="no-underline pv2 db">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img3.jpg?w=800&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img4.jpg?w=800&auto=format&auto=compress"
                />
              </div>
            </div>
            <div className="fl w-50 w-25-ns ph2">
						<div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img-b2.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img-b3.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img5.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img6.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img7.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img8.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img9.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img10.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              {/*<div className="pv2 db no-underline black">
								<img alt=""  loading="lazy" className="db w-100" src="https://ddools.imgix.net/lg/lg-img11.jpg?w=400&auto=format&auto=compress" />
								</div>
							 <div className="pv2 db no-underline black">
								<img alt=""  loading="lazy" className="db w-100" src="https://ddools.imgix.net/lg/lg-img12.jpg?w=400&auto=format&auto=compress" />
								</div> */}
            </div>
            <div className="fl w-50 w-25-ns ph2">
              <div className="pv2 db no-underline black lg-img-b3">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img13.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black lg-img-b3">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img-b5.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black ">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img-b4.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img14.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img15.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img16.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img17.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img18.jpg?w=400&auto=format&auto=compress"
                />
              </div>
              <div className="pv2 db no-underline black">
                <img
                  alt=""
                  loading="lazy"
                  className="db w-100"
                  src="https://ddools.imgix.net/lg/lg-img19.jpg?w=400&auto=format&auto=compress"
                />
              </div>
            </div>
          </div>

          <div className="center measure-wide f5 pv5 lh-copy ph2  ">
            <h2 className="f1 lh-title tc fw2" id="three" >Contact Me</h2>
            <div className="mw8 center dark-gray">
              <section className="dt w-100 ph5-l ph2 pv3 center">
                <p className="">
                  I would love to hear from you, feel free to contact me by any
                  of the below options and I will get back to you as soon as
                  possible.
                </p>
                <div className="">
                  <div className=" w-100 fl">
                    <form
                      method="post"
                      action="https://formspree.io/lauragarriganphotography@gmail.com"
                    >
                      <div className="group">
                        <input
                          type="text"
                          required="required"
                          name="name"
                          id="name"
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="name">Name</label>
                      </div>
                      <div className="group">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          required="required"
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="email">Email</label>
                      </div>
                      <div className="group">
                        <textarea
                          type="textarea"
                          name="message"
                          id="message"
                          rows="5"
                          required="required"
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="message">Message</label>
                      </div>

                      <ul className="list pa2 pl0 tc tl-ns">
                        <li className="ma0">
                          <input
                            className="f6 link dim ba bw1 ph3 pv2 mb2 dib bg-white black b--black pointer ttu"
                            type="submit"
                            value="Send Message"
                          />
                        </li>
                      </ul>
                    </form>
                  </div>
                  <div className=" w-50-ns w-100 fl">
                    <div className="w-100 pt3-ns pl1-ns">
                      <div className="dt dt--fixed  pt2-ns dark-gray">
                        <div className="dtc tc w-10 v-top ">
                          <svg className="h1" viewBox="0 0 20 20">
                            <path d="M18.121,9.88l-7.832-7.836c-0.155-0.158-0.428-0.155-0.584,0L1.842,9.913c-0.262,0.263-0.073,0.705,0.292,0.705h2.069v7.042c0,0.227,0.187,0.414,0.414,0.414h3.725c0.228,0,0.414-0.188,0.414-0.414v-3.313h2.483v3.313c0,0.227,0.187,0.414,0.413,0.414h3.726c0.229,0,0.414-0.188,0.414-0.414v-7.042h2.068h0.004C18.331,10.617,18.389,10.146,18.121,9.88 M14.963,17.245h-2.896v-3.313c0-0.229-0.186-0.415-0.414-0.415H8.342c-0.228,0-0.414,0.187-0.414,0.415v3.313H5.032v-6.628h9.931V17.245z M3.133,9.79l6.864-6.868l6.867,6.868H3.133z" />
                          </svg>
                        </div>
                        <div className="dtc tl v-top w-90 pl3 pb3">
                          Skerries,
                          <br />
                          Dublin.
                        </div>
                      </div>

                      <div className="dt dt--fixed dark-gray">
                        <div className="dtc tc w-10 v-top ">
                          <svg className="h1" viewBox="0 0 20 20">
                            <path d="M13.372,1.781H6.628c-0.696,0-1.265,0.569-1.265,1.265v13.91c0,0.695,0.569,1.265,1.265,1.265h6.744c0.695,0,1.265-0.569,1.265-1.265V3.045C14.637,2.35,14.067,1.781,13.372,1.781 M13.794,16.955c0,0.228-0.194,0.421-0.422,0.421H6.628c-0.228,0-0.421-0.193-0.421-0.421v-0.843h7.587V16.955z M13.794,15.269H6.207V4.731h7.587V15.269z M13.794,3.888H6.207V3.045c0-0.228,0.194-0.421,0.421-0.421h6.744c0.228,0,0.422,0.194,0.422,0.421V3.888z" />
                          </svg>
                        </div>
                        <div className="dtc tl v-top w-90 pl3 pb3">
                          <a
                            href="tel:+353831881742"
                            className="dark-gray "
                            aria-label="My mobile number"
                          >
                            083 188 1742
                          </a>
                        </div>
                      </div>

                      <div className="dt dt--fixed dark-gray">
                        <div className="dtc tc w-10 v-mid">
                          <svg className="h1" viewBox="0 0 20 20">
                            <path d="M17.388,4.751H2.613c-0.213,0-0.389,0.175-0.389,0.389v9.72c0,0.216,0.175,0.389,0.389,0.389h14.775c0.214,0,0.389-0.173,0.389-0.389v-9.72C17.776,4.926,17.602,4.751,17.388,4.751 M16.448,5.53L10,11.984L3.552,5.53H16.448zM3.002,6.081l3.921,3.925l-3.921,3.925V6.081z M3.56,14.471l3.914-3.916l2.253,2.253c0.153,0.153,0.395,0.153,0.548,0l2.253-2.253l3.913,3.916H3.56z M16.999,13.931l-3.921-3.925l3.921-3.925V13.931z" />
                          </svg>
                        </div>
                        <div className="dtc tl v-top w-90 pl3">
                          <a
                            href="mailto:lauragarriganphotography@gmail.com?subject=website enquiry"
                            className="dark-gray"
                            rel="noopener noreferrer"
                          >
                            lauragarriganphotography@gmail.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </article>
				<footer className="pv4 ph3 ph5-m ph6-l bg-mid-gray white">
					<small className="f6 db tc">&copy; 2019 <b className="ttu">LAURA GARRIGAN PHOTOGRAPHY</b>, All Rights Reserved</small>
					<div className="tc mt3">
					<a
                className="dib"
                href="https://www.instagram.com/lauragarriganphotography/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className=" w3 pv2 ph2">
								<IconInsta />
                </div>
              </a>
					</div>
				</footer>
      </Layout>
    );
  }
}

export default HomeIndex;
